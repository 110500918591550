import React from "react";
// import { Link } from "gatsby";
import "../styles/extras.css";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
// import BioImage from "../img/BioImage";

const Index = () => (
  <Layout>
    <Seo />
    <div className="mt-20">
      <article className="col-span-full mx-auto prose prose-lg ">
        <h1>Nathaniel Noyd</h1>
      </article>
    </div>

    <div className="mt-20">
      <article className="col-span-full  mx-auto prose prose-lg border rounded-lg p-5">
        <p>
          {" "}
          <span role="img" aria-label="document emoji">
            📎
          </span>{" "}
          see resume on{" "}
          <a href="https://www.linkedin.com/in/nathanielnoyd">linkedin</a>
        </p>
      </article>
    </div>

    {/* <div className="mt-20">
      <article className="col-span-full  mx-auto prose prose-lg border rounded-lg p-5">
        <h2 className>Currently</h2>
        <h3>
          {" "}
          <span role="img" aria-label="delivery emoji">
            🚗
          </span>{" "}
          Strategy and Operations
        </h3>
        <p>
          I work with DoorDash’s crack team of interdisciplinary problem-solvers
          and creative strategists to build, optimize, and scale a platform that
          empowers local economies.
        </p>
      </article>
    </div> */}

    {/* <div className="mt-20">
      <article className="mx-auto prose prose-lg border rounded-lg p-5">
        <h2>Recently</h2>

        <h3>
          <span role="img" aria-label="grape emoji">
            🍇
          </span>{" "}
          Launching a winery
        </h3>
        <p className="">
          Archibald James is a boutique producer of minimal-intervention wines
          and ciders, handcrafted from the finest fruit in Washington state.
          <br />
          Unique among wineries, we’re lean and agile, and that’s allowed us to
          experiment on the cutting-edge of our industry. We’ve blurred the
          lines between wine and cider, developing fabulous products that
          surprise and delight our customers.
          <br /> <br />
          <div>
            <Link to="/winery">Launching a lean startup winery</Link>{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="inline-flex h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </p>

        <h3>
          {" "}
          <span role="img" aria-label="profile emoji">
            👤{" "}
          </span>{" "}
          Customer-centric leadership
        </h3>

        <p className="">
          I’ve spent most of my life in the hospitality industry, managing
          customer-facing teams and developing a sharper sensitivity to the
          details of the customer experience. I apply empathetic
          design-thinking, starting with the customer and working backwards.
          Considering the experience of all stakeholders balances business
          priorities in a way that leads to interest alignment—the best
          long-term strategy.
        </p>
      </article>
    </div> */}

    <div className="mt-20">
      <article className="mx-auto prose prose-lg border rounded-lg p-5">
        <p>
          <span role="img" aria-label="mailbox emoji">
            📮
          </span>{" "}
          send an email to my name @ this domain
        </p>
      </article>
    </div>
    <div className="mt-20">
      <article className="mx-auto prose prose-lg text-transparent">
        <p>
          Nathaniel Noyd is a interdisciplinary problem solver, business
          strategist, and visionary product leader. He has experience leading
          product strategy in high-growth tech companies, including the leading
          platform marketplaces. As a product leader, Nathaniel has built API
          platforms and developed product partnerships that enable product-led
          growth opportunities while improving operating efficiency and
          improving the customer experience.
        </p>
      </article>
    </div>
  </Layout>
);
export default Index;
